<template>
  <div>
    <ConsultDetail></ConsultDetail>
  </div>
</template>

<script>
import ConsultDetail from '@/components/report/ConsultDetail.vue';
export default {
    components: {
        ConsultDetail
    }
}
</script>

<style>

</style>